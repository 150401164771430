/* @media (max-width: 768px) {
    .header .nav-searchinputs .top-nav-search {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .header .dropdown-menu p {
      font-size: 13px;
    }
  }
  @media (max-width: 768px) {
    .d-flex {
      flex-direction: row !important; 
      justify-content: space-between;
    }
    .m-2 {
      margin:0; 
    }
    .w-100 {
      width: 100%; 
    }
  }
   */
/* Added */
   /* @media (max-width: 768px) {
    .dropdown-menu {
      padding: 0.1rem;
    }
  
    .dropdown-menu .dropdown-item {
      margin: 0; 
      padding: 0.1rem 1rem; 
    }
  
    .dropdown-menu p {
      margin: 0; 
      padding: 0.1rem 0; 
    }
  }
  .dropdown-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    white-space: nowrap; 
  } */
  /* Default styles for desktop */
.dropdown-item {
    display: block; 
    white-space: nowrap; 
    padding: 0.5rem 1rem; 
  }
  
  /* Mobile view (max-width: 768px) */
  @media (max-width: 768px) {
    .dropdown-menu {
      padding: 0.1rem; 
    }
  
    .dropdown-menu .dropdown-item {
      margin: 0; 
      padding: 0.1rem 1rem; 
      display: flex; 
      /* justify-content: space-between;  */
      align-items: center; 
    }
  
    .dropdown-menu p {
      margin: 0; 
      padding: 0.1rem 0; 
    }
  }
  