/* ToggleSwitch.css
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  border-radius: 50%;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color: #4CAF50;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Add a rounded border for the switch */
/*.slider {
  border-radius: 34px;
} */
/* ToggleSwitch.css */
.toggle-switch {
    position: relative;
    display: inline-block;
    width: 40px; /* Reduced width */
    height: 20px; /* Reduced height */
  }
  
  .toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 20px; /* Adjust border radius for smaller switch */
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px; /* Reduced height */
    width: 16px; /* Reduced width */
    border-radius: 50%;
    left: 2px; /* Adjusted position for smaller size */
    bottom: 2px; /* Adjusted position for smaller size */
    background-color: white;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #4CAF50;
  }
  
  input:checked + .slider:before {
    transform: translateX(20px); /* Adjusted transform for smaller size */
  }
  