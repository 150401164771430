
.link-badge {
    display: inline-block;
    word-wrap: break-word; /* Allows long file names to break onto the next line */
    white-space: pre-wrap; /* Ensures the text respects the line breaks */
    max-width: 100%; /* Ensures it takes up the available width */
}

.file-name {
    display: inline-block;
    max-width: 100%; /* Ensures that file names wrap */
    word-wrap: break-word; /* Breaks the file name at spaces or long words */
    overflow-wrap: break-word; /* Prevents overflow if file name is too long */
}
