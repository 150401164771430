#language-selector-btn {
  background-color: transparent;
  border: none;
  font-size: 1rem;
  color: var(--color-black-2);
svg{
    color: var(--color-grey-light-3) !important;
  }
}

#language-selector-btn:focus {
  border: none;
  box-shadow: none;
}

.dropdown-menu {
  font-size: 1rem !important;
  padding: 0 !important;
  .dropdown-item{
    font-size: 1rem ;
    // padding: .5rem 1.5rem ;
  }
}
