.responsive-image {
    padding: 1.2rem;
    max-width: 100%;
    height: auto;
  }
  
  @media (max-width: 768px) {
    .responsive-image {
      margin-left: 1rem;
      max-width: 50%;
    }
  }
  
  @media (min-width: 769px) {
    .responsive-image {
      max-width: 100%;
    }
  }
  
  @media (max-width: 768px) and (orientation: landscape) {
    .responsive-image {
      margin-left: 1 rem;
      max-width: 25%;
    }
  }
  
  @media (max-width: 768px) and (orientation: portrait) {
    .responsive-image {
      margin-left: 1rem;
      max-width: 50%;
    }
  }
  