.quiz {
  font-family: "Nunito", sans-serif;
  box-shadow: 0px 2px 3px rgb(0 0 0 / 12%);
  border-radius: 8px;
  // border: 0;
}

.question.quiz {
  // background-color: #0094da;
  color: var(--color-black-2);
  // padding: 10px;
  // min-height: 60px;
  display: flex;
  // align-items: center;
  // justify-content: center;
  font-size: 1.4rem !important;
  margin: 1rem 0 1rem 0;
  box-shadow: none;

  .results {
    text-align: center;
  }
}

// .quiz.card {
//   .new-result {
//     background: linear-gradient(to bottom, #dbebf8 30%, white 70%);
//   }
// }

.congratulations {
  font-size: 2.5rem;
  color: var(--color-black-2);
  font-weight: 500;
  padding-top: 3rem;
  padding-bottom: 1rem;

  &-sub {
    color: var(--color-grey-light-1);
    font-size: 1.8rem;
  }
}

.results-info {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 18px;
}

.answer {
  width: 100%;
  background: #fff;
  // border: 1px solid var(--color-grey-light-3);
  // min-height: 60px;
  // cursor: pointer;
  // margin-bottom: 20px;
  display: flex;
  border-radius: 50px;
}

.answers {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // margin-top: 50px;
}

.answer-letter {
  font-size: 30px;
  background-color: #0084da;
  color: #fff;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.answer-text {
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-left: 20px;

  .form-label {
    font-size: 1.6rem;
    color: var(--color-black-2);
    font-weight: normal !important;
  }
}

.correct-answer {
  border: 1px solid var(--color-green);
}

.correct-answer .answer-letter {
  background: var(--color-green);
}

.wrong-answer {
  border: 1px solid var(--color-red);
}

.wrong-answer .answer-letter {
  background: var(--color-red);
}

.next-button {
  margin: 0 auto;
  color: #fff;
  background: #0b0e21;
  width: 300px;
  font-size: 18px;
  font-weight: 600;
  padding: 14px 75px;
  text-transform: uppercase;
  cursor: pointer;
  text-align: center;
}

.score {
  color: var(--color-grey-light-1);
  font-size: 2rem;
  text-align: left;
  // margin-bottom: 30px;
}

.disabled-answer {
  pointer-events: none;
}

.question-section {
  padding: 0rem;

  .answers .answer .answer-text {
    font-weight: normal !important;
    width: inherit;
  }
}

.results-heading {
  text-align: center;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-rows: 2rem 0;
  grid-gap: 2rem;

  &:after,
  &:before {
    content: " ";
    display: block;
    border-bottom: 2px solid #067de1;
  }
  img {
    background-color: var(--color-white-1);
    padding: 0.8rem;
    border-radius: 50%;
    width: 50px;
    cursor: pointer;
  }
}

.modal-popup {
  z-index: 9999 !important;
}
.success_img{
   img{
     width: 20rem;
   }
}